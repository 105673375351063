import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SliderData } from '../Static/SliderData';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';



const useStyles = makeStyles((theme)=>({
    root:{
        display:'flex',
        justifyContent:'center',
        alignItems: 'center',  
        fontFamily:'Nunito',
        marginTop:'100px'
    },
    image:{
        position:'relative',
        [theme.breakpoints.down('md')]: {
            height:'230px',width:'200px'        
          },
        [theme.breakpoints.down('sm')]: {
            height:'230px',width:'200px'
          },               
        height:'400px',
        width:'350px',
        borderRadius:'10px',
    },
    rightArrow:{
        [theme.breakpoints.down('xs')]: {
            marginLeft:'10px'
        },
        display:'flex',
        position: 'relative',
        fontSize: '3rem',
        color: 'white',
        cursor: 'pointer',
        userSelect: 'none',
        marginLeft:'100px'
    },
    leftArrow:{
        [theme.breakpoints.down('xs')]: {
            marginRight:'10px'
        },
        position: 'relative',
        fontSize: '3rem',
        color: 'white',
        cursor: 'pointer',
        userSelect: 'none',
        marginRight:'100px'

    },
    slide:{
        opacity: '0',
        transitionDuration:'1s ease',
    },
      
    slideActive:{
        display:'block',
        opacity: '1',
        transitionDuration:'1s',
        transform: 'scale(1.08)',
    },
    
    slider:{
        display:'flex',
        justifyContent:'center',
        alignItems: 'center',  
        width:'60%', 
    },

}));

export default function Slika({ slides }) {
    const [current, setCurrent] = useState(0);
    const length = SliderData.length;
    const classes = useStyles();
    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
      };
    
      const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
      };
    
      if (!Array.isArray(SliderData) || SliderData.length <= 0) {
        return null;
      }
    
    return(
        <div className={classes.root}>
            <div className={classes.slider}>
                <NavigateBeforeIcon className={classes.leftArrow} onClick={prevSlide} />   
                {SliderData.map((slide, index) => {
                return(
                    <div className={index === current ? `${classes.slideActive}` : `${classes.slide}`} key={index}>
                        {index===current && (<img className={classes.image} src={slide.image} alt="travel" />)}      
                    </div>
                )})}
            <NavigateNextIcon className={classes.rightArrow} onClick={nextSlide} />
            </div>
            </div>
    )
}