import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Collapse, CardActions,CardActionArea } from '@material-ui/core';
import  { Link } from 'react-router-dom';



const useStyles = makeStyles(theme =>({
  root: {
    background:'rgba(0,0,0,0.6)',
    margin:"20px",
    width: 450,
    [theme.breakpoints.down('xs')]: {
      width:300
    },   
  },
  media: {
    [theme.breakpoints.down('xs')]: {
      height:180
    }, 
    height: 250,
  },
  title:{
      fontFamily:'Nunito',
      fontWeight:'bold',
      fontSize:'2rem',
      color:'#5AFF3D',
      
  },
  disc:{
    fontFamily:'Nunito',
    fontSize:'1.1rem',
    color:'#ddd',
    textAlign:'center',
    justifyContent:'center'
  },
  buttonaction:{
    justifyContent:'center',
    textAlign:'center',
   
  },
  button:{
    
    color:'#5AFF3D',
    '&:hover': {
      textColor: '#12330C',
    }
  },
  kita:{
    
  }
  
}));

export default function ImageCard({place , checked}) {
  const classes = useStyles();

  return (
    <Collapse in={checked} 
     {...(checked ? { timeout: 1000 } : {})}
     >
    
    <Card className={classes.root}>
    <Link to={place.link} style={{ textDecoration: 'none',color:'white' }} className={classes.kita}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={place.imageUrl}
          title={place.title}
        />
        <CardContent className={classes.buttonaction}>
          <Typography
          className={classes.title}>
            {place.title}
          </Typography>
          <Typography 
          variant="body2" 
          color="textSecondary" 
          component="p"
          className={classes.disc}
          >
            {place.description}
          </Typography>
          </CardContent>
          <CardActions className={classes.buttonaction}>
      </CardActions>
      </CardActionArea>
      </Link>
    </Card>
    </Collapse>
  );
}