import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Grid, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import places from '../Static/static';
import ProjectCard from './ProjectCard';
import pozadina from '../Assets/bg2.svg';
import konstrukcija from '../Assets/9016689.jpg';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Nunito',
    backgroundImage: `url(${pozadina})`,
    backgroundSize: 'cover',
  },
  back: {
    display: 'none',
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  appbar: {
    marginTop: '30px',
    background: 'none',
    position: 'fixed',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: '20px',
    },
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
    },
  },
  appbarTitle: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '&::after': {
      opacity: '0',
      display: 'inline-block',
      content: '"Ostojic.com"',
    },
    '&::before': {
      opacity: '1',
      display: 'inline-block',
      content: '"Zivko"',
    },
    '&:hover': {
      '&::after': {
        transition: 'opacity 1s ease,transform 0.5s',
        opacity: '1',
        transform: 'translate(-25px)',
        color: '#5AFF3D',
      },
      '&::before': {
        transition: 'transform 0.5s',
        transform: 'translate(-25px)',
      },
    },
    color: 'white',
    flexGrow: '1',
  },
  maliekran: {
    display: 'none',
    color: 'white',
    [theme.breakpoints.up('xs')]: {
      display: 'none',
      marginLeft: '-15px',
    },
  },
  appbarWrapper: {
    width: '90%',
    margin: '0 auto',
  },
  link: {
    opacity: '1',
    color: 'white',
    textDecoration: 'none',
    zIndex: '110',
  },
  about: {
    width: '100%',
    minHeight: '100vh',
    paddingTop: '80px',
    paddingBottom: '60px',
  },
  wrapper: {
    [theme.breakpoints.down('xs')]: {
      margin: '0 10px',
    },
    color: 'white',
    maxWidth: '800px',
    margin: '0 auto',
  },
  text: {
    letterSpacing: '-1px',
    textAlign: 'left',
    color: '#fff',
    fontSize: '2.5rem',
    [theme.breakpoints.up('xs')]: {
      fontSize: '2.3rem',
    },
    margin: '0 auto',
    fontWeight: 'bold',
    lineHeight: '2.5rem',
    marginTop: '40px',
  },
  disc: {
    fontSize: '1.2rem',
    lineHeight: '2rem',
    textAlign: 'left',
    width: '100%',
    color: 'white',
    marginBottom: '100px',
  },
  maintenance: {
    display: 'flex',
    justifyContent: 'center', // Centers content horizontally
    alignItems: 'center', // Centers content vertically
    flexDirection: 'column',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35%' /* Adjust based on your preference */,
    height: '35%',
    padding: '20px',
    backgroundColor: 'rgba(200, 200, 200, 0.9)' /* Or any other color */,
    border: '1px solid #ccc' /* Optional */,
    borderRadius: '10px' /* Optional for rounded corners */,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' /* Optional for a drop shadow */,
    textAlign: 'center',
    zIndex: '100' /* High enough to be above other elements */,
    '& img': {
      // Nested styling for img
      width: '100%', // Adjust based on your preference
      height: '100%',
      marginBottom: '10px', // Space between image and text
    },
    '& p': {
      // Nested styling for p
      margin: 0,
      fontSize: '40px', // Adjust based on your preference
      color: 'green', // Text color
    },
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background
    zIndex: 120, // Ensure it's below the popover but above other content
  },
}));

export default function Projects() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar} elevation={0}>
        <Toolbar className={classes.appbarWrapper}>
          <Link to='/' className={classes.link}>
            <KeyboardBackspaceIcon className={classes.back} fontSize='large' />
            <span className={classes.appbarTitle}></span>
            <h1 className={classes.maliekran}>zivko</h1>
          </Link>
        </Toolbar>
      </AppBar>
      <div className={classes.overlay}></div>
      <div className={classes.maintenance}>
        <img src={konstrukcija} alt='maintenance' />
      </div>
      <Container className={classes.about} disableGutters>
        <div className={classes.wrapper}>
          <h1 className={classes.text}>Software developer portfolio</h1>
          <p className={classes.disc}>
            From React.JS, Redux and Axios to some backend .NET, Java and
            NodeJS. Check out my latest software development portfolio projects.
          </p>
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <ProjectCard place={places[6]} />
            <ProjectCard place={places[3]} />
            <ProjectCard place={places[4]} />
            <ProjectCard place={places[7]} />
          </Grid>
        </div>
      </Container>
    </div>
  );
}
