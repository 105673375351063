import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageCard from './ImageCard';
import places from '../Static/static';
import useWindowPosition from '../Hooks/useWindowPosition';
import { Grid } from '@material-ui/core';
import pozadina from '../Assets/bg3.svg'

const useStyles = makeStyles((theme)=>({
    root:{
        minHeight:'100vh', 
        justifyContent:'center',
        alignItems: 'center',
        display:'flex',
        backgroundImage:`url(${pozadina})`,
        backgroundSize:'cover',
        backgroundColor:'#0a272b',   
    },
}));

export default function MyCard() {
    const classes = useStyles();
    const checked = useWindowPosition('header') 
    return(
        <div className={classes.root} id="place-to-visit">
            <Grid 
            container 
            direction="row" 
            justify="space-evenly"
            alignItems="center">
            <ImageCard place={places[0]} checked={checked}/>
            <ImageCard place={places[1]} checked={checked}/>
            <ImageCard place={places[2]} checked={checked}/>
            </Grid>
        </div>
    )
}