import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Pdf from '../Assets/zivko.pdf';
import pozadina from '../Assets/bg2.svg'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Slika from './Slika'


const useStyles = makeStyles((theme) => ({

    root: {
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Nunito',
        backgroundImage: `url(${pozadina})`,
        backgroundSize: 'cover',
    },
    back:{
        display:'none',
        color:'white',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        },
        [theme.breakpoints.down('md')]: {
            display: 'flex',
        },
    },
    appbar:{
        marginTop:'30px',
        background:'none',
        position:'fixed',
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            right:'20px'
        },
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
        },
    },
    appbarTitle: {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },

        '&::after': {
            opacity: '0',
            display: 'inline-block',
            content: '"Ostojic.com"'
        },
        '&::before': {
            opacity: '1',
            display: 'inline-block',
            content: '"Zivko"',

        },
        '&:hover': {
            '&::after': {
                transition: 'opacity 1s ease,transform 0.5s',
                opacity: '1',
                transform: 'translate(-25px)',
                color: '#5AFF3D',
            },
            '&::before': {
                transition: 'transform 0.5s',
                transform: 'translate(-25px)',
            }
        },
        color: 'white',
        flexGrow: '1',

    },
    maliekran: {
        display: 'none',
        color: 'white',
        [theme.breakpoints.up('xs')]: {
            display: 'none', marginLeft: '-15px'
        },
        

    },
    appbarWrapper: {
        width: '90%',
        margin: '0 auto',
    },
    icon: {
        color: '#fff',
        fontSize: '2rem',
    },
    colorText: {
        color: '#5AFF3D',
    },
    about: {
        width: '100%',
        minHeight: '100vh',
        paddingTop: '80px',
        paddingBottom: '60px',
    },
    wrapper: {
        [theme.breakpoints.down('xs')]: {
            margin: '0 10px'
        },
        color: 'white',
        maxWidth: '770px',
        margin: '0 auto',
    },
    title: {
        textAlign: 'left',
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '2.5rem',
        color: '#fff',
        lineHeight: '2.5rem',
        letterSpacing: '-1px',
        marginTop:'40px',
        [theme.breakpoints.up('xs')]: {
            fontSize:'2.3rem'
        },
    },
    disc: {
        fontFamily: 'Nunito',
        fontSize: '1.2rem',
        color: '#ddd',
        textAlign: 'left',
    },
    link: {
        opacity: '1',
        color: 'white',
        textDecoration: 'none',
    },
    pdf: {
        color: '#5AFF3D',
        textDecoration: 'none',
        display: 'block',
        transition: '0.5 ease',
        '&:hover': {
            transition: 'transform 0.5s ease',
            transform: 'translate(+25px)',
        }
    }

}));

export default function Aboutme() {
    const classes = useStyles();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className={classes.root}>
            <AppBar className={classes.appbar} elevation={0}>
                <Toolbar className={classes.appbarWrapper}>
                    <Link to="/" className={classes.link}>
                    <KeyboardBackspaceIcon className={classes.back} fontSize="large"/>
                        <span className={classes.appbarTitle}></span>
                        <h1 className={classes.maliekran}>zivko</h1>
                    </Link>
                </Toolbar>
            </AppBar>
            <Container className={classes.about} disableGutters>
                <div className={classes.wrapper}>
                    <h1 className={classes.title}>About</h1>
                    <Typography
                        className={classes.disc}
                    >
                        Hi there!<br /><br /> I’m a software developer who loves building user-friendly websites and various apps.
            I’m fluent in JavaScript and the JS/TS web stack (Node, Express, React/Redux) and proficient in OOP. Most recently, I’ve been building web apps with React and serverless providers like Firebase and Netlify.
            <br />
                        <br />
            I don’t like to define myself by the work I've done, but rather by the work I want to do. I want to keep on learning, continue to challenge myself and do things that matter.
            <br />
                        <br />
            When I’m not coding you’ll find me swimming, gaming or working out in the park.
            <br />
                        <br />
                        <a className={classes.pdf} href={Pdf}>My resume(pdf)</a>
                    </Typography>

                    < Slika />
                </div>
            </Container>
        </div>
    )
}