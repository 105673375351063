import React, { useEffect,useState } from 'react';
import MyCard from './MyCard';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Collapse, IconButton, Toolbar } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Link as Scroll} from 'react-scroll';
import pozadina from '../Assets/bg1.svg'
import {useSpring , animated} from 'react-spring';

const useStyles = makeStyles((theme)=>({
    root: {
        height:'100vh',
        display: 'flex',
        justifyContent:'center',
        alignItems: 'center',  
        fontFamily:'Nunito', 
        backgroundImage:`url(${pozadina})`,
        backgroundSize:'cover',
        backgroundColor:'#0a272b', 
    },
    appbar:{
        background:'none',
        marginTop:'30px',

    },
    appbarTitle:{
        color:'white',
        flexGrow:'1',
    },
    appbarWrapper:{
        width: '90%',
        margin: '0 auto',
    },
    icon:{
        color: '#fff',
        fontSize:'2rem',
    },
    colorText:{
        color:'#5AFF3D',
    },
    title:{
        color:'#fff',
    },
    bla:{
        textAlign:'center',
        fontSize: '1.6rem',
    },
    hehe:{
        '&:hover':{
            backgroundColor:'rgba(0,0,0,0.01)',
        }
    },
    goDown:{
        color:'#5AFF3D',
        fontSize:'4rem',
    },
}));

export default function Header() {
    const classes = useStyles();
    const [checked,setChecked]=useState(false);
    const [ajmo,setAjmo]=useState(false);
    const props = useSpring({
        transform : ajmo ? 'translateY(-20px)' : 'translateY(0px)'
          })
    useEffect(()=>{
        setChecked(true);
    },[]);
   const toggle = () => {
       setTimeout(() => {
           setAjmo(!ajmo)
       }, 500);
   }
    toggle();      

    return(<>
        <div className={classes.root} id="header">
            <AppBar className={classes.appbar} elevation = {0}>
                <Toolbar className={classes.appbarWrapper}>
                <h1 className={classes.appbarTitle}>Zivko<span className={classes.colorText}>Ostojic
                    </span></h1>
                </Toolbar>
            </AppBar>
            <Collapse 
            className={classes.kita}
            in={checked}
            {...(checked ? { timeout: 2000 } : {})} 
            collapsedHeight={50}
            >
            <div className={classes.bla}>
                <h1 className={classes.title}>
                    Welcome to <br /> My
                     <span className={classes.colorText}>Portfolio.</span>
                </h1>
                <Scroll
                to="place-to-visit" 
                smooth={true}> 
                <IconButton className={classes.hehe}>
                    <animated.div style={props}>
                    <ExpandMoreIcon className={classes.goDown}/>
                    </animated.div>
                </IconButton>
                </Scroll>
            </div>
            </Collapse>
        </div>
        <MyCard />
        </>
    )
}