import slika1 from '../Assets/aboutme.svg';
import slika3 from '../Assets/projects.svg';
import slika9 from '../Assets/contact1.svg';
import timesheet from '../Assets/timesheet.jpg';
import timesheetmodal from '../Assets/timesheetmodal.jpg';
import MERN from '../Assets/MERN.png';
import photobook2 from '../Assets/Photobook2.jpg';
import restaurant from '../Assets/Restaurant.png';
import restaurant1 from '../Assets/Restaurant1.png';
import buysell from '../Assets/buysell.jpg';
import buysell2 from '../Assets/buysell2.jpg';
import ReactMaps from '../Assets/ReactMaps.png';
import ReactMaps1 from '../Assets/ReactMaps1.png';

const places = [
  {
    title: 'About me',
    description: 'Get to know me better.',
    imageUrl: `${slika1}`,
    time: 1500,
    link: '/aboutme',
  },
  {
    title: 'Projects',
    description: 'Check out some of my work.',
    imageUrl: `${slika3}`,
    time: 1500,
    link: '/projects',
  },
  {
    title: 'Contact',
    description: 'Need to get in touch? Feel free to contact me.',
    imageUrl: `${slika9}`,
    time: 1500,
    link: '/contacts',
  },
  {
    title: 'Timesheet React/.NET project',
    description: 'react',
    imageUrl: `${timesheet}`,
    time: 1500,
    link: 'https://zile-timesheet.netlify.app/',
    imageUrls: `${timesheetmodal}`,
  },
  {
    title: 'Photobook MERN project',
    description: 'mern',
    imageUrl: `${MERN}`,
    time: 1500,
    link: 'https://zileks.netlify.app/',
    imageUrls: `${photobook2}`,
  },
  {
    title: 'Vanilla JS Restaurant App',
    description: 'Vanilla JS',
    imageUrl: `${restaurant1}`,
    time: 1500,
    link: 'https://restaurantfilter.netlify.app/',
    imageUrls: `${restaurant}`,
  },
  {
    title: 'Buy/Sell MERN Project',
    description: 'MERN',
    imageUrl: `${buysell}`,
    time: 1500,
    link: 'https://buysell-app.netlify.app',
    imageUrls: `${buysell2}`,
  },
  {
    title: 'TravelHelper App',
    description: 'React/GoogleMaps',
    imageUrl: `${ReactMaps}`,
    time: 1500,
    link: 'https://tripadvisory.netlify.app/',
    imageUrls: `${ReactMaps1}`,
  },
];

export default places;
