import React,{ useEffect } from 'react';
import { BrowserRouter,Route,Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import Header  from './Components/Header'
import Aboutme from './Components/Aboutme'
import Project from './Components/Projects'
import Contact from './Components/Contacts'
import { createMuiTheme } from '@material-ui/core/styles'
import { MuiThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline:{
      '@global':{
        '.Mui-disabled': { opacity:0.5,},
        '.MuiInput-underline':{borderBottom:'0px solid white'},
      }
    },
    MuiInput: {
      underline: {
        "&:before":{
        borderBottom:'none'},
        "&&&&:hover:before": {
          borderBottom: '2px solid #595959'
        }
      }
    }
  },
  palette: {
    primary:{
      main:'#fff'
    },
    action: {
      disabledBackground: '#333333',
      disabled: 'white'
    }
  }
});
const useStyles = makeStyles((theme)=>({
root:{
      minHeight:'100vh', 
},
}));

export default function App() {
    useEffect(() => {
    window.scrollTo(0, 0)
      } , [])
    const classes = useStyles();
    return(
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline/>
          <Switch>
          <Route path="/" exact component={Header}/>
          <Route path="/projects" component={Project}/>
          <Route path="/aboutme" component={Aboutme}/>
          <Route path="/contacts" component={Contact}/>
          </Switch>
        </div>
        </MuiThemeProvider>
      </BrowserRouter>
    )
}
