import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {AppBar,Container, Toolbar,Grid,TextField, Button} from '@material-ui/core';
import {Link} from 'react-router-dom';
import AccountCircle from '@material-ui/icons/AccountCircle';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import useForm from '../Hooks/useForm'
import emailjs from 'emailjs-com';
import pozadina from '../Assets/bg2.svg'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';



const useStyles = makeStyles((theme)=>({
    root:{
        minHeight:'100vh',
        display: 'flex',
        justifyContent:'center',
        alignItems: 'center',  
        fontFamily:'Nunito',
        backgroundImage: `url(${pozadina})`,
        backgroundSize:'cover',
    },
    back:{
        display:'none',
        color:'white',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        },
        [theme.breakpoints.down('md')]: {
            display: 'flex',
        },
    },
    appbar:{
        marginTop:'30px',
        background:'none',
        position:'fixed',
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            right:'20px'
        },
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
        },
    },
    appbarTitle:{
        fontWeight:'bold',
        fontSize:'1.5rem',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }, 
        '&::after':{
            opacity:'0',
            display:'inline-block',
            content:'"Ostojic.com"'
        },
        '&::before':{
            opacity:'1',
            display:'inline-block',
            content:'"Zivko"',  
            
        },
        '&:hover': {
            '&::after':{
                transition: 'opacity 1s ease,transform 0.5s',
                opacity:'1',
                transform: 'translate(-25px)',
                color:'#5AFF3D',
            },
            '&::before':{
                transition:'transform 0.5s',
                transform:'translate(-25px)',
            }
        },
        color:'white',   
        flexGrow:'1',
        
    },
    maliekran:{
        display:'none',
        color:'white',
        [theme.breakpoints.up('xs')]: {
            display: 'none', marginLeft: '-15px'
        },
        
        
    },  
    appbarWrapper:{
        width: '90%',
        margin: '0 auto',
    },
    link:{
        opacity:'1',
        color:'white',
        textDecoration: 'none',
        
    },
    about:{
        width:'100%',
        minHeight:'100vh',
        paddingTop:'80px',
        paddingBottom:'60px',
        
    },
    wrapper:{
        [theme.breakpoints.down('xs')]: {
            margin:'0 10px'
          },
        color:'white',
        maxWidth:'770px',
        margin:'0 auto',
    },
    header:{
        [theme.breakpoints.up('xs')]: {
            fontSize:'2.3rem'
        },
        marginTop:'40px',
        letterSpacing:'-1px',
        color:'white',
        fontSize:'2.5rem',
        margin: '0 auto',
        textAlign: 'left',
        lineHeight:'2.5rem'
    },
    disc:{
        fontSize:'1.2rem',
        lineHeight:'2rem',
        textAlign: 'left',
        width:'100%'    
    },
    fancy:{
        display:'inline-block',
        position:'relative',
        '&::after':{
            content: '" "',
            position: 'absolute',
            height: '16px',
            width: '0',
            bottom: '0',
            right: '0',
            backgroundColor: '#333333',
            transitionDelay: '0.1s',
            transition:'width .325s cubic-bezier(.65,.2,0,1)'
        },
        '&::before':{
            content: '" "',
            position: 'absolute',
            height: '16px',
            width: '0',
            top: '0',
            left: '0',
            backgroundColor: '#333333',
            transitionDelay: '0s',
            transition:'width .325s cubic-bezier(.65,.2,0,1)'
        },
        '&:hover': {
            '&::after':{
                width:'100%'
            },
            '&::before':{
                width:'100%'
            }
        },
        
    },
    mail:{
        display:'inline-block',
        fontWeight:'bold',
        textDecoration:'none',
        position:'relative',
        zIndex:'3',
        color:'white',
        '&:hover': {
        color:'#f2f2f2',
        transition: 'color .25s ease-out .1s'
        }
    },
    texty:{
        marginTop:'50px',
        borderBottomColor:'white !important',
        display:'block',
        position:'relative', 
    },
    button:{
        fontWeight:'bold',
        fontFamily:'Nunito',
        textAlign:'center',
        margin:'20px 10px',
        color:'white',
        backgroundColor:'#075528',
        '&:hover':{
            backgroundColor:'#053d1d'
        },
    },
    social:{
        marginTop:'60px',
        fontSize:'1.6rem'
    },
    instagram:{
        display:'inline-block',
        '&:hover':{
            transform:'scale(1.2)',
            color:'#c13584',
            transition:'0.5s ease'
        },
        position:'relative',
        margin:'0 10px',
        textDecoration:'none',
        color:'white',
        width:'50px',
    },
    facebook:{
        display:'inline-block',
        '&:hover':{
            transform:'scale(1.2)',
            color:'#3b5998',
            transition:'0.5s ease'
        },
        position:'relative',
        margin:'0 10px',
        textDecoration:'none',
        color:'white',
        width:'50px',
    },
    linkedin:{
        display:'inline-block',
        '&:hover':{
            transform:'scale(1.2)',
            color:'#0077b5',
            transition:'0.5s ease'
        },
        position:'relative',
        margin:'0 10px',
        textDecoration:'none',
        color:'white',
        width:'50px',
    },
    github:{
        display:'inline-block',
        '&:hover':{
            transform:'scale(1.2)',
            color:'#333',
            transition:'0.5s ease'
        },
        position:'relative',
        margin:'0 10px',
        textDecoration:'none',
        color:'white',
        width:'50px',
    },
    input:{
        color:'white',
        fontFamily:'Nunito',
        marginLeft:'5px',
        fontSize:'1.2rem',
        transition:'0.6s',
    },
    label:{
        position:'relative',
        backgroundColor:'#262626',
        height:'40px',
        marginLeft:'25px',
        marginTop:'50px',
    },
    label2:{
        position:'relative',
        marginLeft:'15px',
        backgroundColor:'#262626',
        height:'40px',
        marginTop:'50px',

    }
   
}));

export default function Contacts({callback}) {
    const {
        values,
        handleChange,
        resetForm
    } = useForm(true)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const classes = useStyles();
    const [idemo,setIdemo] = useState(true);
    const [idemobre,setIdemobre] =useState(true);
    const [enabled1,setEnabled1] = useState('block');
    const [enabled2,setEnabled2] = useState('none');
    const [enabled3,setEnabled3] = useState('none');

    useEffect(() => {
     if(enabled1==='block' && values.name.length >=3 && !values.email){
         setIdemo(false)
     }
     else if(enabled2==='block' && /\S+@\S+\.\S+/.test(values.email)===true){
         setIdemo(false)
     }
     else setIdemo(true)
     if((values.name && values.email) && values.message.length >10){
         setIdemobre(false)  
     }   
    },[values,enabled1,enabled2]);

    const handleSubmit = e => {
        e.preventDefault();
        if(enabled1 ==='block'){
            setEnabled2('block')
            setEnabled1('none')
            setEnabled3('none')
            setIdemo(true)
        }
        if((enabled2) === 'block' ){
            setEnabled2('none')
            setEnabled1('none')
            setEnabled3('block')
            setIdemo(true)
        }
        if((enabled3) === 'block'){
            setEnabled2('none');
            setEnabled1('block');
            setEnabled3('none');
            emailjs.send('service_77q79r8', 'template_ee0svds', values , 'user_3PktKVNgFBrRz7iVTt1Gg')
                .then((result) => 
            {
                alert("Thank you! Your message has been successfully sent. I will contact you very soon!");
            }, (error) => 
            {
                alert(error.text);
            });
            setIdemobre(true)
            resetForm(values)
            
        }
      }
      console.log(values)

    return(
        <div className={classes.root}>
            <AppBar className={classes.appbar} elevation = {0}>
                <Toolbar className={classes.appbarWrapper}>
                <Link to="/" className={classes.link} >
                <KeyboardBackspaceIcon className={classes.back} fontSize="large"/>
                <span className={classes.appbarTitle}></span>
                <h1 className={classes.maliekran}>zivko</h1>
                    </Link>   
                </Toolbar>
            </AppBar>
            <Container className={classes.about} disableGutters>
                <div className={classes.wrapper}>
                    <h1 className={classes.header}>Get in touch</h1>
                    <p className={classes.disc}>If you wanna get in touch, talk to me about a project collaboration or just say hi,
                    fill up the form below or send an email to <span  className={classes.fancy}><a href className={classes.mail}>zivko.ostojic93@gmail.com</a></span>
                    , and let's talk.</p>
                    
                    <div className={classes.texty}>
                        <form onSubmit={handleSubmit} noValidate name="form">
                        <Grid container alignItems="flex-end">
                            <Grid item>
                                <AccountCircle />
                            </Grid>
                            <Grid item xs={11}>
                                <TextField
                                name="name"
                                required
                                value={values.name}
                                style={{display:enabled1}}
                                onChange={handleChange}
                                autoComplete="off"
                                fullWidth={true}
                                label="Your name" 
                                InputLabelProps={{className: classes.input}} 
                                inputProps={{className: classes.input}}
                                />
                                <TextField
                                type="email"
                                name="email"
                                value={values.email}
                                required
                                style={{display:enabled2}}
                                onChange={handleChange}
                                autoComplete="off"
                                fullWidth={true} 
                                id="input-with-icon-grid" 
                                label="Now your email"
                                InputLabelProps={{className: classes.input}} 
                                inputProps={{className: classes.input}}
                                />
                                <TextField
                                name="message"
                                value={values.message}
                                required
                                style={{display:enabled3}}
                                onChange={handleChange}
                                autoComplete="off"
                                fullWidth={true} 
                                label="Write your message"
                                InputLabelProps={{className: classes.input}} 
                                inputProps={{className: classes.input}}
                                />    
                            </Grid>
                            
                            <Button disabled={idemo} variant="contained" className={classes.button} type="submit">NEXT</Button>
                            <Button disabled={idemobre} variant="contained" className={classes.button} type="submit">SUBMIT YOUR MESSAGE</Button>
                            <Grid item xs={2}>
                            </Grid>
                        </Grid>
                        </form>
                        <div>
                            <h3 className={classes.social}>Let's get social</h3>
                            <p className={classes.disc}>Feel free to reach out through any platforms below.</p>
                            <a href="http://facebook.com/zivko.ostojic" className={classes.facebook}><FacebookIcon fontSize="large"/></a>
                            <a href="http://instagram.com/zileks" className={classes.instagram}><InstagramIcon fontSize="large"/></a>
                            <a href="http://linkedin.com//in/zivko-ostojic" className={classes.linkedin}><LinkedInIcon fontSize="large"/></a>
                            <a href="http://github.com/Zileks" className={classes.github}><GitHubIcon fontSize="large"/></a>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}