import slika1 from '../Assets/ns.jpg'
import slika2 from '../Assets/divcibare.jpg'
import slika3 from '../Assets/slika1.jpg'
import slika4 from '../Assets/slika.jpg'

export const SliderData = [
    {
        image:`${slika1}`
    },
    {
      image:`${slika2}`
    },
    {
        image:`${slika3}`
    },
    
    {
        image:`${slika4}`
    }
  ];