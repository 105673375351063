import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { CardHeader } from '@material-ui/core';




const useStyles = makeStyles(theme =>({
  root: {
    [theme.breakpoints.down('xs')]: {
      width:320,
    },   
    fontFamily:'Nunito',
    textAlign:'center',
    justifyContent:'center',
    background:'rgba(0,0,0,0.5)',
    margin:"20px",
    width: 350,
    opacity:'0.9',
    '&:hover':{
        opacity:'0.75',
        "& $media2":{
            display:'block',
            opacity:'1',
            transition:' 0.9s ease',
            height:250,
        },
        "& $media":{
            display:'none'
        }  
    }
  },
  media: {
    height: 250,
  },
  media2:{
    opacity:'0',
  },
  kita:{
    opacity:'0',
},
  cardHead:{
    color:'gray',
    height:'5vh',
    fontFamily:'Nunito',
    fontWeight:'bold',
    fontSize:'1rem',
    backgroundColor:'white'
  },
  
 
  

 
  
}));

export default function ProjectCard({place}) {
  const classes = useStyles();
  return (
    <Card className={classes.root} >
    <a href={place.link} style={{ textDecoration: 'none',color:'white' }}>
      <CardHeader
        disableTypography={true}
        className={classes.cardHead}
        title={place.title}/>
        <CardMedia
          className={classes.media}
          image={place.imageUrl}
        />
        <CardMedia
        className={classes.media2}
        image={place.imageUrls}/>
      </a>
    </Card>
    
  );
}